import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const expenseCatDetailsApi = createApi({
  reducerPath: "expenseCatDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["ExpenseCatDetail"],
  endpoints: (builder) => ({
    getExpenseCatDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.expenseCatDetails}/${id}`,
      }),
      providesTags: ["ExpenseCatDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});



export const {  useGetExpenseCatDetailsByIdQuery } = expenseCatDetailsApi;
