import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const expenseListDetailsApi = createApi({
  reducerPath: "expenseListDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["ExpenseListDetail"],
  endpoints: (builder) => ({
    getExpenseListDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.expenseListDetails}/${id}`,
      }),
      providesTags: ["ExpenseListDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});



export const {  useGetExpenseListDetailsByIdQuery } = expenseListDetailsApi;
