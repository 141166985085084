import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const expenseCatList = createApi({
  reducerPath: "expenseCatList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["ExpenseCat"],
  endpoints: (builder) => ({
    getExpenseCatData: builder.query({
      query: ({
        page = 1,
        limit,
         
        search,
        pagination = true,
        
      }) => ({
        url: `${Apis.expenseCatList}`,
        params: {
          page,
          limit, 
          search,
          pagination, 
        },
      }),
      providesTags: ["ExpenseCat"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetExpenseCatDataQuery } = expenseCatList;
